@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');
@import './mixins';

//Main palette
$palette: (
  50: #e4e4e4,
  100: #bcbcbd,
  200: #8f9091,
  300: #626364,
  400: #414143,
  500: #1f2022,
  600: #1b1c1e,
  700: #171819,
  800: #121314,
  900: #0a0b0c,
  A100: #d1ded1,
  A200: #a5c4a8,
  A400: #59ab65,
  A700: #155224
);

$pallet2: (
  color1: #67696e,
  color-2: #5b5d61,
  color-3: #4f5054,
  color-4: #434447,
  color-5: #2e303b,
  color-6: #1d1f2e,
  color-7: #040508,
  color-8: #000000
);

$contrast: (
  50: #000000,
  100: #000000,
  200: #000000,
  300: #ffffff,
  400: #ffffff,
  500: #ffffff,
  600: #ffffff,
  700: #ffffff,
  800: #ffffff,
  900: #ffffff,
  A100: #000000,
  A200: #000000,
  A400: #000000,
  A700: #000000
);

@function main-palette($key: $palette) {
  @return map-get($palette, $key);
}

@function main-contrast($key: $palette) {
  @return map-get($contrast, $key);
}

//Accent palette
$accent-palette: (
  50: #dedce4,
  100: #d8d6e0,
  200: #b1afc2,
  300: #8c89a5,
  400: #696588,
  500: #46436d,
  600: #232452,
  700: #000038,
  800: #040031,
  900: #06002a,
  A100: #f0ffff,
  A200: #e4f2f2,
  A400: #ccd7d9,
  A700: #9ab8bf
);

// 500: #399d4b,

$accent-contrast: (
  50: #000000,
  100: #000000,
  200: #000000,
  300: #000000,
  400: #000000,
  500: #ffffff,
  600: #ffffff,
  700: #ffffff,
  800: #ffffff,
  900: #ffffff,
  A100: #000000,
  A200: #000000,
  A400: #000000,
  A700: #000000
);

@function accent-palette($key: $accent-palette) {
  @return map-get($accent-palette, $key);
}

@function accent-contrast($key: $accent-palette) {
  @return map-get($accent-contrast, $key);
}

:root {
  --main-color: main-palette(500);
  --main-bg: #fff;
  --boxed-width: 1140px;
}

$main-color: main-palette(500);
$main-bg: #fff;
$boxed-width: 1140px;

$success-color: #b7ce63;
$success-contrast: #000;
$info-color: #64b5f6;
$info-contrast: #000;
$warning-color: #e9e165;
$warning-contrast: #000;
$error-color: #ed5564;
$error-contrast: #fff;

//Main
$main-fs: 14px;
$main-ff: 'Lato', sans-serif;
$main-fw: 400;
$module: 10px;
$main-lh: (
  $module * 2 / $main-fs
);
$module-rem: (
  $module / $main-fs) * 1rem;
$shape: 6px;
$shadow: 0 18px 24px rgba(#000, 0.12
);
$shadow-hover: 0px 8px 24px rgba(#000, 0.12);
$animation: ease-in-out;

//Second
$second-ff: $main-ff;

//Media
$max543: 'only screen and (max-width: 543px)';

$min544: 'only screen and (min-width: 544px)';

$max767: 'only screen and (max-width: 767px)';

$min768: 'only screen and (min-width: 768px)';

$max991: 'only screen and (max-width: 991px)';

$min992: 'only screen and (min-width: 992px)';

$max1199: 'only screen and (max-width: 1199px)';

$min1200: 'only screen and (min-width: 1200px)';

//Headers
$headers-ff: $second-ff;
$headers-fw: 700;

//navbar
$navbar-bg: #eeeeef;
$navbar-color: rgba(main-palette(500), 0.5);
$vertical-navbar-width: $module-rem * 24;

//footer
$footer-height: $module-rem * 6;

//Preloader
$loader-overlay-bg: #fff;
$loader-color: accent-palette(500);

//Other Colors
$p-one-blue: #78C14E;

// Color
$white: #ffffff;

$primary: #000038;
$blue: #6666FF;
$secondary: #78C14E;
$success: #52CC99;
$warning: #FFBF66;
$info: #66CCFF;
$danger: #D7263D;
$purple: #aa65e8;
$dark: #24272c;
$black: #161c2d;
$muted: #8492a6;
$light: #f2f6fa;

// Gray
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;

//Body Background
$shadow: 0 0 3px rgba($dark, .15);
$shadow-md: 0 5px 13px rgba($dark, .2);
$shadow-lg: 0 25px 25px rgba($dark, 0.05);
$footer: lighten($black, 6%);

// Base font
$font-size-base: 16px;
$font-size-menu: 14px;

$font-family-base: 'DM Sans', sans-serif;
$font-family-secondary: 'DM Sans', sans-serif;

// Overlay
$overlay: rgba($black, 0.75);
$card-overlay: linear-gradient(to bottom, transparent 0%, transparent 25%, transparent 35%, rgba($black, 0.9) 100%);

$colors: (
  "primary": $primary,
  "blue": $blue,
  "secondary": $secondary,
  "success": $success,
  "warning": $warning,
  "info": $info,
  "danger": $danger,
  "dark": $dark,
  "muted": $muted,
  "light": $light,
  "footer": $footer
);

$heading-font-sizes: (
  "h1, .fs-1": 42px,
  "h2, .fs-2": 36px,
  "h3, .fs-3": 30px,
  "h4, .fs-4": 24px,
  "h5, .fs-5": 18px,
  "h6, .fs-6": 16px,
);

$display-font-sizes: (
  "display-1": 80px,
  "display-2": 72px,
  "display-3": 64px,
  "display-4": 56px,
  "display-5": 48px,
  "display-6": 40px,
  )