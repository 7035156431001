@import '../../../assets/sass/variables';

.app-search {
  width: 40%;
  max-width: 25rem;

  @media (max-width: 767px) {
    // max-width: 20rem !important;
  }

  .ant-select-arrow {
    top: 50%;
    transform: translateY(-50%);
    font-size: 18px;
    margin: 0;
    right: $module-rem * 2;

    height: 20px;
    width: 20px;
  }

  .ant-input-affix-wrapper .ant-input-suffix {
    right: $module-rem * 2.5;

    >* {
      font-weight: 500;
      opacity: 0.4;
      font-size: 1.25rem;
    }
  }
}