.custom-actions {
  .col-12 {
    flex: 0 0 0;
  }
}

.referesh-table {
  cursor: pointer;
}

.dashboard-card {
  position: relative;

  h3 {}
}

.recharts-background {
  background-color: #f0f0f3;
  background-image: linear-gradient(#f0f0f3, #9ab8bf);
}

// Chateau Green
// #399D4B

// Athens Gray
// #F0F0F3

// Ebony Clay
// #1D1F2E

// Shadow Green
// #9AB8BF