/*!
 * Bootstrap v4.3.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

// Core variables and mixins
@import 'bootstrap/variables';
@import 'bootstrap/mixins';
@import 'bootstrap/custom';

// Reset and dependencies
@import 'bootstrap/normalize';
@import 'bootstrap/print';

// Core CSS
@import 'bootstrap/reboot';
@import 'bootstrap/type';
@import 'bootstrap/images';
@import 'bootstrap/code';
@import 'bootstrap/grid';
@import 'bootstrap/tables';
@import 'bootstrap/forms';
@import 'bootstrap/buttons';

// Components
//@import "bootstrap/transitions";
//@import "bootstrap/dropdown";
//@import "bootstrap/button-group";
//@import "bootstrap/input-group";
//@import "bootstrap/custom-forms";
//@import "bootstrap/nav";
//@import "bootstrap/navbar";
//@import "bootstrap/card";
//@import "bootstrap/breadcrumb";
//@import "bootstrap/pagination";
//@import "bootstrap/badge";
//@import "bootstrap/jumbotron";
//@import "bootstrap/alert";
//@import "bootstrap/progress";
//@import "bootstrap/media";
//@import "bootstrap/list-group";
//@import "bootstrap/responsive-embed";
//@import "bootstrap/close";

// Components w/ JavaScript
//@import "bootstrap/modal";
//@import "bootstrap/tooltip";
//@import "bootstrap/popover";
//@import "bootstrap/carousel";

// Utility classes
@import 'bootstrap/utilities';